<template>
  <div class="container">
    <div class="m-auto w-25">
      <router-link class="text-center" to="/login">&larr; Sign In</router-link>
    </div>

    <form v-stream:submit="submit$">
      <TextInput
        v-focus
        v-if="!isRecovery"
        required
        :validator="$v.currentPassword"
        :validatorMsgMap="validatorMsgMap"
        v-model="currentPassword"
        class="text-left"
        name="password"
        label="Old Password"
        type="password"
        autocomplete="current-password"
        mode="password"
        id="password"
      />
      <TextInput
        required
        :validator="$v.newPassword"
        :validatorMsgMap="validatorMsgMap"
        v-model="newPassword"
        class="text-left"
        name="password"
        label="Password"
        type="password"
        autocomplete="new-password"
        mode="password"
      />
      <TextInput
        required
        v-model="confirm"
        mode="password"
        autocomplete="new-password"
        :validator="$v.confirm"
        :validatorMsgMap="validatorMsgMap"
        class="text-left my-2"
        name="confirm password"
        label="Confirm Password"
        type="password"
      />
      <p id="error" v-if="isCapsLockOn" class="text-danger mt-2">Caps lock is enabled.</p>
      <div v-if="$v.newPassword.$invalid" class="my-4">
        <!-- <p class="font-weight-medium mb-1">Password Requirements</p> -->
        <ul class="text-danger p-0 mt-2">
          <transition-group class="list">
            <li
              class="list-item"
              :key="key"
              v-for="key in Object.keys($v.newPassword.$params).filter(e => e !== 'required')"
            >
              {{ validatorMsgMap[key] }}
            </li>
          </transition-group>
        </ul>
      </div>

      <div class="d-flex flex-column align-items-center mt-2">
        <div class="error text-center mt-2 mb-0">
          <p id="error" v-if="error" class="text-danger">{{ error }}</p>
          <loader size="small" v-else-if="loading"></loader>
        </div>
        <router-link :to="{ name: 'forgotPassword' }" class="mx-auto my-2 align-self-center" href
          >Forgot Password?</router-link
        >
        <button :disabled="loading" type="submit" class="mx-auto my-2 btn btn-primary">
          Submit
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { catchError, filter, switchMap, tap } from "rxjs/operators";
import Users from "../../../services/users";
import { mapActions, mapState, mapGetters } from "vuex";
import { required, sameAs, minLength, helpers } from "vuelidate/lib/validators";
import { createLogItem, validatorMsgMapBase } from "../../../modules/helpers";
import auditLog from "../../../services/AuditLog";
import TextInput from "@/components/common/TextInput.vue";
import Loader from "@/components/common/Loader.vue";
const passwordComplexity =
  /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*_.~`()+=[\]{}:\-;'"<>,?|/\\]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*/i;

export default {
  name: "ChangePassword",
  components: {
    TextInput,
    Loader
  },
  props: {
    email: { required: false },
    token: { required: false },
    redirect: {
      default() {
        return "/";
      }
    }
  },
  data() {
    return {
      newPassword: "",
      confirm: "",
      currentPassword: "",
      loading: false,
      error: "",
      isCapsLockOn: false
    };
  },
  validations() {
    return {
      newPassword: {
        minLength: minLength(8),
        required,
        complexity: helpers.regex("nonAlpha", passwordComplexity)
      },
      confirm: {
        minLength: minLength(8),
        sameAs: sameAs("newPassword"),
        required
      },
      currentPassword: {
        required: value => {
          if (!this.isRecovery) {
            return helpers.req(value);
          }
          return true;
        }
      }
    };
  },
  mounted() {
    window.addEventListener("keyup", this.handleKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeyUp);
  },
  domStreams: ["submit$", "pinRequest$"],
  subscriptions() {
    const submit$ = this.submit$.pipe(
      tap(({ event }) => event.preventDefault()),
      filter(() => {
        this.$v.$touch();
        return !this.$v.$invalid;
      })
    );

    const changePassword$ = submit$.pipe(
      switchMap(async () => {
        if (this.isRecovery) {
          await Users.resetPassword({
            email: this.email,
            token: encodeURIComponent(this.token),
            password: this.newPassword
          });
          return this.$router.push({
            name: "Login",
            query: {
              redirect: this.redirect
            }
          });
        }
        await Users.changePassword({
          oldPassword: this.currentPassword,
          newPassword: this.newPassword
        });
        this.changedPassword();
        auditLog.insertLogMessage({ ...createLogItem({}, 16), comments: "Changed password" });
        return this.loadApp();
      }),
      catchError(error => {
        this.loading = false;
        if (error.response?.data) {
          this.error = error.response.data;
        }
        return changePassword$;
      })
    );
    return {
      changePassword$
    };
  },
  computed: {
    ...mapState(["currentUser", "currentLab", "availableLabs"]),
    ...mapGetters(["mustSelectLab"]),
    validatorMsgMap() {
      return {
        ...validatorMsgMapBase,
        nonAlpha: "Must include at least one none alpha numeric character.",
        minLength: "Must be at least 8 characters.",
        sameAs: "Must match the password field.",
        notSame: "New password cannot be your old password.",
        complexity:
          "Must include one special character, one uppercase letter, one lowercase letter & one number."
      };
    },
    isRecovery() {
      if (/reset/.test(this.$route.fullPath)) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions(["changedPassword"]),
    async loadApp() {
      this.loading = true;
      if (this.currentLab) {
        this.loadingMsg = "";
      } else if (this.availableLabs.length === 1) {
        this.$store.dispatch("setCurrentLab", this.availableLabs[0].id);
      }
      if (this.mustSelectLab) {
        return;
      }
      this.$router.push("/");
    },
    handleKeyUp(event) {
      this.isCapsLockOn = event.getModifierState("CapsLock");
    }
  }
};
</script>

<style lang="scss" scoped>
.pin-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
